/**
THIS IS AUTOMATICALLY GENERATED by DataCollection.write_ts_interface()
DO NOT EDIT DIRECTLY.
CREATED 2021-01-20 10:49:49Z
**/

export type ArrayOfColumns = number[][]
export type IsoDateString = string
export type IsoDateTimeString = string

export interface Label {
    slug: string
    verbose: string
}

export interface BarChartDataSet {
    data: ArrayOfColumns[]
    xLabels: string[]
    seriesLabels: Label[]
    siteNames: Label[]
}

export interface HeatMapDataSet {
    xLabels: string[]
    yLabels: string[]
    data: ArrayOfColumns
}

export type ForecastLevel = "HIGH" | "MED" | "LOW"

export interface ForecastCard {
    dateLabel: string
    airTemperature: string
    levelDisplay: string
    levelSlug: ForecastLevel
    algalBloom: string
    toxins: string
}

export interface OtherHomePageDataSet {
    forecasts: ForecastCard[]
    siteDescription: string
}

export type DataSetTypes = OtherHomePageData | TimeSeriesData | BandedTimeSeriesData | HeatMapData | BarChartData

export interface OtherHomePageData {
    slug: string
    type: OtherHomePageData
    dataSet: OtherHomePageDataSet
}
export interface TimeSeriesData {
    slug: string
    type: TimeSeriesData
    units: string
    dataSet: [IsoDateTimeString | IsoDateString, number][]
}
export interface BandedTimeSeriesData {
    slug: string
    type: BandedTimeSeriesData
    units: string
    yThresholds: [string, number, number][]
    dataSet: [IsoDateString | IsoDateTimeString, number, number, number, number | null][]
    displayName: string
}
export interface HeatMapData {
    slug: string
    type: HeatMapData
    units: string
    dataSet: HeatMapDataSet
}
export interface BarChartData {
    slug: string
    type: BarChartData
    units: string
    dataSet: BarChartDataSet
}

export interface BaseApiResponse {
    fullPath: string
    filename: string
    dataSets: DataSetTypes[]
    createdAt: IsoDateTimeString
    forecastDate: IsoDateString
}

export enum HOME_PAGE_API_ENDPOINTS {
    DETROIT_LAKE__HOME__BLOWOUT = "/detroit-lake/home/blowout/blowout.json",
    DETROIT_LAKE__HOME__HEATER_CREEK = "/detroit-lake/home/heater-creek/heater-creek.json",
    DETROIT_LAKE__HOME__LOG_BOOM = "/detroit-lake/home/log-boom/log-boom.json"
    
}

export enum DASHBOARD_MET_API_ENDPOINTS {
    DETROIT_LAKE__DASHBOARD__AIR_TEMP = "/detroit-lake/dashboard/air-temp/air-temp-all.json",
    DETROIT_LAKE__DASHBOARD__PRECIPITATION = "/detroit-lake/dashboard/precipitation/precipitation-all.json",
    DETROIT_LAKE__DASHBOARD__WIND_SPEED = "/detroit-lake/dashboard/wind-speed/wind-speed-all.json",
    DETROIT_LAKE__DASHBOARD__HUMIDITY = "/detroit-lake/dashboard/humidity/humidity-all.json",
    DETROIT_LAKE__DASHBOARD__SOLAR_RADIATION = "/detroit-lake/dashboard/solar-radiation/solar-radiation-all.json"
    
}

export enum DASHBOARD_SITE_API_ENDPOINTS {
    DETROIT_LAKE__DASHBOARD__NUTRIENTS = "/detroit-lake/dashboard/nutrients/nutrients-all.json",
    DETROIT_LAKE__DASHBOARD__TOXIN = "/detroit-lake/dashboard/toxin/toxin-all.json",
    DETROIT_LAKE__DASHBOARD__ALGAE = "/detroit-lake/dashboard/algae/algae-all.json"
    
}

export enum DASHBOARD_YSI_API_ENDPOINTS {
    DETROIT_LAKE__DASHBOARD__DISSOLVED_OXYGEN = "/detroit-lake/dashboard/dissolved-oxygen/dissolved-oxygen-all.json",
    DETROIT_LAKE__DASHBOARD__WATER_TEMP = "/detroit-lake/dashboard/water-temp/water-temp-all.json",
    DETROIT_LAKE__DASHBOARD__CHLOROPHYLL_A = "/detroit-lake/dashboard/chlorophyll-a/chlorophyll-a-all.json",
    DETROIT_LAKE__DASHBOARD__TURBIDITY = "/detroit-lake/dashboard/turbidity/turbidity-all.json",
    DETROIT_LAKE__DASHBOARD__PH = "/detroit-lake/dashboard/ph/ph-all.json"
    
}

export enum SO_WHAT_PAGE_API_ENDPOINTS {
    DETROIT_LAKE__SO_WHAT = "/detroit-lake/so-what/so-what.json"
    
}

export interface HomePageResponse extends BaseApiResponse {
    dataSets: [OtherHomePageData, BandedTimeSeriesData, BandedTimeSeriesData, HeatMapData, BandedTimeSeriesData, BandedTimeSeriesData]
}

export interface DashboardMetResponse extends BaseApiResponse {
    dataSets: [TimeSeriesData, TimeSeriesData, TimeSeriesData, TimeSeriesData, TimeSeriesData, TimeSeriesData]
}

export interface DashboardSiteResponse extends BaseApiResponse {
    dataSets: [BarChartData, BarChartData, BarChartData, BarChartData, BarChartData, BarChartData]
}

export interface DashboardYsiResponse extends BaseApiResponse {
    dataSets: [HeatMapData, HeatMapData, HeatMapData, HeatMapData, HeatMapData, HeatMapData]
}

export interface SoWhatPageResponse extends BaseApiResponse {
    dataSets: [BandedTimeSeriesData, BandedTimeSeriesData, BarChartData, TimeSeriesData, TimeSeriesData, TimeSeriesData, TimeSeriesData]
}



