import { Router } from "@reach/router"
import MainLayout from "components/organisms/Layout/MainLayout"
import React from "react"
import DataDashboardPage from "./pages/DataDashboardPage"
import HomePage from "./pages/HomePage"
import ProjectPage from "./pages/ProjectPage"
import StoryPage from "./pages/StoryPage"
import { Route, RouteInfo } from "./Route"

interface RouterProps {
    className?: string
    style?: React.CSSProperties
}

export enum PATHS {
    ROOT = "/*",
    HOME = "/home",
    DASHBOARD = "/dashboard",
    PROJECT = "/the-project",
    STORY = "/story"
}

const navigationGuards = {
    layout: (to: RouteInfo, from?: RouteInfo): Promise<string | boolean> => {
        return new Promise(resolve => {
            if (to && (to.fullPath === "/*" || to.fullPath === "/")) {
                return resolve(PATHS.HOME)
            }
            return resolve(true)
        })
    },
    app: async (to: RouteInfo, from?: RouteInfo): Promise<string | boolean> => {
        // do auth & other checks here
        return true
    }
}

const Layout: React.FC<RouterProps> = props => (
    <Router className={props.className} style={props.style}>
        <Route path={PATHS.ROOT} guard={navigationGuards.layout} component={MainLayout} />
    </Router>
)

const Main: React.FC<RouterProps> = props => {
    return (
        <div className={props.className} style={props.style}>
            <Router style={{ height: "100%" }}>
                <Route guard={navigationGuards.app} path={PATHS.HOME} component={HomePage} />
                <Route guard={navigationGuards.app} path={PATHS.DASHBOARD} component={DataDashboardPage} />
                <Route guard={navigationGuards.app} path={PATHS.PROJECT} component={ProjectPage} />
                <Route guard={navigationGuards.app} path={PATHS.STORY} component={StoryPage} />
            </Router>
        </div>
    )
}

export const AppRouter = { Layout, Main }
