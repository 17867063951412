import { Moment } from "moment-timezone"
import moment from "moment-timezone"

export enum CONSTANTS {
    isoFormatString = "%Y-%m-%d %H:%M:%SZ",
    xFormatString = "%m/%-d/%y"
}

export const formatDateToMonth = (date: Date) => {
    return `${date.toLocaleString("en-us", { month: "short" })}`
}

// This returns a timezone aware moment object. If there is a trailing "Z"
// the date string is assumed to be UTC, else it defaults to "America/Los_Angeles"
export const dateStringToMomentDate = (dateString: string): Moment => {
    if (dateString[dateString.length - 1].toLowerCase() == "z") {
        // This is UTC date
        return moment.utc(dateString)
    } else {
        return moment.tz(dateString, "America/Los_Angeles")
    }
}

export const getDateString = (date: Moment): string => {
    return date.format("MM/DD/YY")
}

export const getDateTimeString = (date: Moment): string => {
    return date.format("YYYY-MM-DD HH:mm:ss[Z]")
}

export const utcStrToLocalString = (dateString: string) => {
    const momentDate = dateStringToMomentDate(dateString)
    // This is to keep the date in the proper date.
    // The browser will always try to present the date the local timezone
    // i.e. it does not respect the 'Z' so we force it into PST.
    momentDate.add(8, "hours")
    return getDateTimeString(momentDate)
}

export const momentToDate = (momentDate: Moment): Date => {
    const dateStr = momentDate.format()
    const out = new Date(dateStr)
    return out
}

export type Filter<T, U> = T extends U ? T : never

export const formatNumber = (value: number) => {
    if (value < 1) {
        return `${Number(value).toFixed(2)}`
    } else if (value >= 1 && value < 10) {
        return `${Number(value).toFixed(1)}`
    } else if (value >= 10 && value < 10000) {
        return `${Number(value).toFixed(0)}`
    } else if (value > 10000) {
        const num = value.toExponential(1)
        const [base, exp] = num.split("e+")
        return `${num}`
    }
    return `${value}`
}

export const getEnvironment = (): string => {
    const host = window.location.host
    let env = "unknown"
    if (host.includes("localhost")) {
        env = "local"
    } else if (host.includes("staging")) {
        env = "staging"
    } else if (host.includes("detroitlake.thepredictionlab")) {
        env = "production"
    }
    return env
}
