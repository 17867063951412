import React from "react"
import { observer } from "mobx-react"
import moment from "moment-timezone"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { getDateString } from "../../../utils/utils"
import { Moment } from "moment-timezone/moment-timezone"
import { TODAY_COLOR } from "../../../style/config/palette"

export interface TimelineProps {
    forecastDate: Moment
}

const useStyles = makeStyles((theme: Theme) => ({
    timeLine: {
        stroke: "#aaa",
        strokeWidth: 2,

        "& text": {
            fontSize: "16px"
        }
    },
    timeLineLabels: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        "& > div:first-child": {
            justifySelf: "left"
        },
        "& > div": {
            justifySelf: "center"
        },
        "& > div:last-child": {
            justifySelf: "end"
        },
        fontSize: "16px"
    }
}))

const Timeline: React.FC<TimelineProps> = props => {
    const classes = useStyles()

    const TIMELINE_HEIGHT = 35
    const TIMELINE_TICK_HALF_LENGTH = 10
    const TIMELINE_DATE_YPOSITION = 3.5 * TIMELINE_TICK_HALF_LENGTH

    const numTicks = 29
    const delta = 100 / numTicks
    const xTicks: string[] = []
    for (let i = 0; i <= numTicks; i++) {
        xTicks.push(`${i * delta}%`)
    }
    const nowTick = `${(14 + 0.5) * delta}%`

    let minDate = moment(props.forecastDate)
    minDate.subtract(14, "days")
    let maxDate = moment(props.forecastDate)
    maxDate.add(14, "days")

    return (
        <div>
            <div className={classes.timeLineLabels}>
                <div>Past</div>
                <div>Today</div>
                <div>Future</div>
            </div>
            <svg height={TIMELINE_HEIGHT} width="100%">
                <line className={classes.timeLine} x1="0" y1={TIMELINE_TICK_HALF_LENGTH} x2="100%" y2={TIMELINE_TICK_HALF_LENGTH} />
                {xTicks.map((tick, i) => {
                    return <line key={i} className={classes.timeLine} x1={tick} y1="0" x2={tick} y2={2 * TIMELINE_TICK_HALF_LENGTH} />
                })}
                <circle cx={nowTick} cy={TIMELINE_TICK_HALF_LENGTH} r="4" stroke={TODAY_COLOR} strokeWidth="3" fill={TODAY_COLOR} />
                <text x="0" y={TIMELINE_DATE_YPOSITION}>
                    {getDateString(minDate)}
                </text>
                <text x={nowTick} y={TIMELINE_DATE_YPOSITION} textAnchor="middle">
                    {getDateString(props.forecastDate)}
                </text>
                <text x="100%" y={TIMELINE_DATE_YPOSITION} textAnchor="end">
                    {getDateString(maxDate)}
                </text>
            </svg>
        </div>
    )
}

export default observer(Timeline)
