// import { Theme as NivoTheme } from "@nivo/core"
import { defaultTheme } from "style"

export const nivoTheme: any = {
    background: "transparent",
    fontFamily: defaultTheme.typography.body2.fontFamily,
    fontSize: 16,
    textColor: defaultTheme.palette.text.primary,
    axis: {
        domain: {
            line: {
                stroke: "transparent",
                strokeWidth: 1
            }
        },
        ticks: {
            line: {
                stroke: defaultTheme.palette.text.primary,
                strokeWidth: 1
            },
            text: {
                color: defaultTheme.palette.text.secondary,
                fillOpacity: 0.72
            }
        },
        legend: {
            text: {
                fontSize: 16
            }
        }
    },
    grid: {
        line: {
            stroke: "#dddddd",
            strokeWidth: 1
        }
    },
    legends: {
        text: {
            fill: defaultTheme.palette.text.primary,
            fontFamily: defaultTheme.typography.body2.fontFamily
        }
    },
    labels: {
        text: {
            text: {
                fontSize: 16
            }
        }
    },
    markers: {
        lineColor: "#000000",
        lineStrokeWidth: 1
        // text: {}
    },
    dots: {
        text: {}
    },
    tooltip: {
        container: {
            background: "white",
            color: "inherit",
            fontSize: "inherit",
            borderRadius: "2px",
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "5px 9px"
        },
        basic: {
            whiteSpace: "pre",
            display: "flex",
            alignItems: "center"
        },
        chip: {
            marginRight: 7
        },
        table: {},
        tableCell: {
            padding: "3px 5px"
        }
    },
    crosshair: {
        line: {
            stroke: "#000000",
            strokeWidth: 1,
            strokeOpacity: 0.75,
            strokeDasharray: "6 6"
        }
    },
    annotations: {
        text: {
            fontSize: 13,
            outlineWidth: 2,
            outlineColor: "#ffffff"
        },
        link: {
            stroke: "#000000",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff"
        },
        outline: {
            fill: "none",
            stroke: "#000000",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff"
        },
        symbol: {
            fill: "#000000",
            outlineWidth: 2,
            outlineColor: "#ffffff"
        }
    }
}
