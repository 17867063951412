import axios, { AxiosResponse } from "axios"
import { observable } from "mobx"
import {
    DashboardMetResponse,
    DashboardSiteResponse,
    DashboardYsiResponse,
    DASHBOARD_MET_API_ENDPOINTS,
    DASHBOARD_SITE_API_ENDPOINTS,
    DASHBOARD_YSI_API_ENDPOINTS,
    HomePageResponse,
    HOME_PAGE_API_ENDPOINTS,
    SoWhatPageResponse,
    SO_WHAT_PAGE_API_ENDPOINTS
} from "../../data-collection"
import { Store } from "../Store"
import Base from "./Base"

const getBaseApiUrl = () => {
    const host = window.location.host
    let baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    if (host.includes("localhost")) {
        // baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/staging/current"
        baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    } else if (host.includes("staging")) {
        // baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/staging/current"
        baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    }
    return baseApiUrl
}

const BASE_API_URL = getBaseApiUrl()

export interface State {
    cachedResponses: Map<
        HOME_PAGE_API_ENDPOINTS | DASHBOARD_YSI_API_ENDPOINTS | DASHBOARD_SITE_API_ENDPOINTS | DASHBOARD_MET_API_ENDPOINTS | SO_WHAT_PAGE_API_ENDPOINTS,
        HomePageResponse | DashboardSiteResponse | DashboardMetResponse | DashboardYsiResponse | SoWhatPageResponse
    >
    homePageData?: HomePageResponse
    dashboardMetDataList: [DashboardMetResponse | undefined, DashboardMetResponse | undefined]
    dashboardSiteData?: DashboardSiteResponse
    dashboardYsiData?: DashboardYsiResponse
    projectPageData?: SoWhatPageResponse
}

export default class Data extends Base {
    @observable
    state: State = {
        cachedResponses: new Map(),
        homePageData: undefined,
        dashboardMetDataList: [undefined, undefined],
        dashboardSiteData: undefined,
        dashboardYsiData: undefined,
        projectPageData: undefined
    }

    actions = {
        getHomePageData: async (apiEndpoint: HOME_PAGE_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.homePageData = this.state.cachedResponses.get(apiEndpoint) as HomePageResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<HomePageResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.homePageData = response.data
            return response.data
        },

        getDashboardSiteData: async (apiEndpoint: DASHBOARD_SITE_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.dashboardSiteData = this.state.cachedResponses.get(apiEndpoint) as DashboardSiteResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<DashboardSiteResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.dashboardSiteData = response.data
            return response.data
        },

        getDashboardYsiData: async (apiEndpoint: DASHBOARD_YSI_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.dashboardYsiData = this.state.cachedResponses.get(apiEndpoint) as DashboardYsiResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<DashboardYsiResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.dashboardYsiData = response.data
            return response.data
        },

        getDashboardMetData: async (apiEndpoints: (DASHBOARD_MET_API_ENDPOINTS | undefined)[]) => {
            this.state.dashboardMetDataList = [undefined, undefined]
            await apiEndpoints.forEach(async (apiEndpoint, i) => {
                if (!apiEndpoint) {
                    return
                }
                if (this.state.cachedResponses.has(apiEndpoint)) {
                    this.state.dashboardMetDataList[i] = this.state.cachedResponses.get(apiEndpoint) as DashboardMetResponse
                    return
                }
                const url = `${BASE_API_URL}${apiEndpoint}`
                const response: AxiosResponse<DashboardMetResponse> = await axios.get(url)
                this.state.cachedResponses.set(apiEndpoint, response.data)
                this.state.dashboardMetDataList[i] = response.data
            })
        },
        getProjectPageData: async (apiEndpoint: SO_WHAT_PAGE_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.projectPageData = this.state.cachedResponses.get(apiEndpoint) as SoWhatPageResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<SoWhatPageResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.projectPageData = response.data
            return response.data
        }
    }

    async init(store: Store) {
        // do any init stuff
        return Promise.resolve()
    }
}
